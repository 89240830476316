import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Modal } from '@carbon/react';

import { useFocusAtom } from '../../../../hooks';
import { gsFiltersAtom } from '../../steps/generalSettings/GeneralSettingsAtoms';

import { scenarioAtom } from '../../../../hooks/wizardAtoms';
import { fulltimeEquationSelectionsAtom, useWlfEquationsState } from './opsFulltimeEquation/wlfEquationsState';
import { deleteOpsFulltimeEquation, fetchOpsFullTimeEquation } from '../../aircrewApiCalls';

export const DeleteFullTimeEqnModal = ({
    openDeleteFulltimeModel,
    setOpenDeleteFulltimeModel,
    onSubmit = () => {},
}) => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const { CMD, MDS, ILC } = gsFilters;

    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');

    const { insertBulkData } = useWlfEquationsState();

    const [selections, setSelections] = useAtom(fulltimeEquationSelectionsAtom);

    const handleResetFulltimeEquations = async (wlfEquation) => {
        if (wlfEquation?.isBaseline === false) {
            return await deleteOpsFulltimeEquation(scenario_id, wlfEquation?.eqnId);
        }
    };

    return (
        <Modal
            open={openDeleteFulltimeModel !== null}
            modalHeading="Are you sure you want to delete this fulltime equation?"
            primaryButtonText="Delete"
            secondaryButtonText="Cancel"
            onRequestClose={() => setOpenDeleteFulltimeModel(null)}
            onRequestSubmit={async () => {
                await handleResetFulltimeEquations(openDeleteFulltimeModel);
                const { data, isOk } = await fetchOpsFullTimeEquation({
                    cmd: CMD,
                    mds: MDS,
                    ilc: ILC,
                    aircrew_type: openDeleteFulltimeModel?.aircrewType,
                });
                const dataToInsert = isOk ? data : [];
                if (openDeleteFulltimeModel) {
                    const { aircrewType, eqnId } = openDeleteFulltimeModel;
                    setSelections({
                        ...selections,
                        [aircrewType]: {
                            ...selections[aircrewType],
                            [eqnId]: false,
                        },
                    });
                }
                insertBulkData(dataToInsert, openDeleteFulltimeModel?.aircrewType, true);
                setOpenDeleteFulltimeModel(null);
                onSubmit();
            }}
            data-testid="ops-equations-delete-ft-eqn-modal"
            className="confirmation-modal"
        />
    );
};

DeleteFullTimeEqnModal.propTypes = {
    openDeleteFulltimeModel: PropTypes.oneOfType([
        null,
        PropTypes.shape({
            aircrewType: PropTypes.string,
            eqnId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
    ]),
    setOpenDeleteFulltimeModel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
};
