import React from 'react';
import { useAtom, atom } from 'jotai';
import PropTypes from 'prop-types';
import {
    DataTable,
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
    TableExpandHeader,
    TableSelectAll,
} from '@carbon/react';

import { FulltimeEquationRow } from './FulltimeEquationRow';

const adminTableHeaders = [
    { header: 'CMD', key: 'cmd' },
    { header: 'ILC', key: 'ilcSpecific' },
    { header: 'FAC', key: 'fac' },
    { header: 'AFSC', key: 'afsc' },
    { header: 'OSC', key: 'osc' },
    { header: 'IAF', key: 'iaf' },
    { header: 'POSITION', key: 'aircrewType' },
    { header: '', key: 'copy' },
    { header: '', key: 'edit' },
];

const tableHeaders = [
    { header: 'ILC', key: 'ilcSpecific' },
    { header: 'FAC', key: 'fac' },
    { header: 'AFSC', key: 'afsc' },
    { header: 'OSC', key: 'osc' },
    { header: 'IAF', key: 'iaf' },
    { header: 'POSITION', key: 'aircrewType' },
    { header: '', key: 'copy' },
    { header: '', key: 'edit' },
];

export const adminCreateModelsPageAtom = atom(false);
export const useAdminCreateModels = () => {
    // Hook for knowing to render the Admin create version
    // Dynamically show CMD
    const [adminCreateModelsPage] = useAtom(adminCreateModelsPageAtom);
    const tableHeadersToUse = adminCreateModelsPage ? adminTableHeaders : tableHeaders;

    return {
        tableHeaders: tableHeadersToUse,
        onlyUserCanEdit: adminCreateModelsPage,
        shouldUseUnrestrictedCMD: adminCreateModelsPage,
    };
};

export const FulltimeEquationDataTable = ({
    eqnRows,
    open,
    showFulltimeEquation,
    ILC,
    onOpenClick,
    onDeleteModel,
    aircrewType,
    onDuplicateRow,
    disabled,
    ilcList = [],
}) => {
    const { tableHeaders, onlyUserCanEdit, shouldUseUnrestrictedCMD } = useAdminCreateModels();
    return (
        <DataTable rows={eqnRows} headers={tableHeaders} id="fulltime-equation--inputs-table">
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getSelectionProps,
                getExpandHeaderProps,
            }) => {
                return (
                    <Table
                        className="table-datatable"
                        {...getTableProps()}
                        data-testid="fulltime-equation--inputs-table"
                    >
                        <TableHead className="table-head">
                            <TableRow>
                                <TableExpandHeader enableToggle {...getExpandHeaderProps()} />
                                {!shouldUseUnrestrictedCMD ? (
                                    <TableSelectAll className="ft-eqn-select-all" {...getSelectionProps()} disabled />
                                ) : null}
                                {headers.map((header) => (
                                    <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                        <div className="d-flex align-items-center">{header.header}</div>
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                            {rows.map((row) => (
                                <FulltimeEquationRow
                                    key={row.id}
                                    aircrewType={aircrewType}
                                    open={open}
                                    disabled={disabled}
                                    eqnId={row.id}
                                    showFulltimeEquation={showFulltimeEquation}
                                    ILC={ILC}
                                    getRowProps={getRowProps}
                                    getSelectionProps={getSelectionProps}
                                    headers={tableHeaders}
                                    row={row}
                                    onOpenClick={onOpenClick}
                                    onDeleteModel={onDeleteModel}
                                    onDuplicateRow={onDuplicateRow}
                                    onlyUserCanEdit={onlyUserCanEdit}
                                    shouldUseUnrestrictedCMD={shouldUseUnrestrictedCMD}
                                    ilcList={ilcList}
                                />
                            ))}
                        </TableBody>
                    </Table>
                );
            }}
        </DataTable>
    );
};

FulltimeEquationDataTable.propTypes = {
    eqnRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    showFulltimeEquation: PropTypes.string,
    ILC: PropTypes.string,
    onOpenClick: PropTypes.func.isRequired,
    onDeleteModel: PropTypes.func.isRequired,
    aircrewType: PropTypes.oneOf(['ENL', 'OFF']).isRequired,
    onDuplicateRow: PropTypes.func,
};
